const validateCurrentSocialCare = (currentSocialCare) => {
  // type: DataTypes.ENUM('CIN', 'CP', 'Early Help', 'CLA / CiC', 'Unknown', 'None', 'Not recorded / Unknown', '.')
  const upperCaseCurrentSocialCare = currentSocialCare ? currentSocialCare.toUpperCase().replace(/ /g, '') : null

  switch (upperCaseCurrentSocialCare) {
    case 'CIN':
      return 'CIN'
    case 'CP':
      return 'CP'
    case 'EARLYHELP':
      return 'Early Help'
    case 'CLA/CIC':
      return 'CLA / CiC'
    case 'UNKNOWN':
      return 'Unknown'
    case 'NONE':
      return 'None'
    case 'NOTRECORDED/UNKNOWN':
      return 'Not recorded / Unknown'
    case '.':
      return '.'
    default:
      throw new Error(
        'CurrentSocialCare value not recognised. Please check your data. ' + currentSocialCare
      )
  }
}

export {
  validateCurrentSocialCare
}