<template>
  <div class="login__form">
    <form @submit.prevent="$emit('login', login)" class="form">
      <img src="@/assets/images/logo.png" width="180" alt="Invision Logo" />
      <p class="login__title">Login to the Annual Review QA Tool</p>
      <p class="error" v-if="error">{{ error }}</p>
      <div class="field">
        <span class="icon input-icon">
          <i class="far fa-envelope"></i>
        </span>
        <input
          type="email"
          class="input has-icon"
          autocomplete="email"
          placeholder="Your email address"
          v-model="login.email"
          @input="$emit('clear-error')"
        />
      </div>
      <div class="field">
        <span class="icon input-icon">
          <i class="far fa-envelope"></i>
        </span>
        <span class="icon input-icon icon-end">
          <i @click="togglePassword" :class="showPassword ? 'fa fa-eye  ' : 'fa fa-eye-slash '"></i>
        </span>
        <input
          v-if="showPassword"
          type="text"
          class="input has-icon"
          autocomplete="password"
          placeholder="Your password"
          v-model="login.password"
          @input="$emit('clear-error')"
        />
        <input
          v-else
          type="password"
          class="input has-icon"
          autocomplete="password"
          placeholder="Your password"
          v-model="login.password"
          @input="$emit('clear-error')"
        />
      </div>
      <div>
        <p class="disclaimer forgot-link">
          <router-link to="/forgotten">
            Forgot Password?
          </router-link>
        </p>
        <button class="login__button">Log in</button>
        <p class="disclaimer">
          By clicking 'Log in' you hereby accept the conditions set out in our
          <a href="https://www.invision360.com/privacy-policy">
            Privacy Policy
          </a>
        </p>
      </div>
      <hr />
      <p class="disclaimer">
        If you need to access the Annual Review QA tool for the first time,
        or have forgotten your password, contact your administrator
      </p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  props: ['error'],
  data() {
    return {
      login: {
        email: '',
        password: ''
      },
      showPassword: false
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/components.scss';

.login {
  &__form {
    position: relative;
    background: white;
    box-shadow: 0px 10px 20px rgba(100, 100, 100, 0.5);
    border-radius: 16px;
    height: 570px;
    width: 460px;
    padding: 1.5rem 2rem;
  }

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  &__button {
    background: $brand-gradient;
    height: 2.5rem;
    width: 100%;
    margin-bottom: 14px;
    border-radius: 18px;
    border: none;
    font-size: 1rem;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }

  .form {
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  hr {
    border: 0px;
    border-bottom: 1px solid $fill-grey;
    width: 100%;
  }

  .disclaimer {
    text-align: center;
    font-size: 0.8rem;
  }

  .error {
    color: red;
    text-align: center;
  }

  .forgot-link {
    text-align: right;
    margin-bottom: 15px;
  }
}
</style>