<template>
  <div class="modal is-active">
    <div class="modal-background" @click.stop="cancel" />
    <div class="modal-content">
      <div class="modal-title">{{ title }}</div>
      <div class="modal-body">
        <slot></slot>
      </div>
      <div class="modal-footer">
        <button class="button is-gradient" @click="close">
          {{ buttonText }}
        </button>
        <button class="button is-cancel" v-if="hasCancel" @click="cancel">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      required: true
    },
    buttonText: {
      type: String
    },
    hasCancel: {
      type: Boolean
    }
  },
  methods: {
    cancel () {
      this.$emit('modal-cancel')
    },
    close () {
      this.$emit('modal-close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/modal.scss";

.button {
  width: 70px;
}
</style>
