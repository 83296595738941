<template>
  <div class="requestReset__form">
    <form @submit.prevent="$emit('request-reset', requestResetData)" class="form">
      <img src="@/assets/images/logo.png" width="180" alt="Invision Logo" />
      <p class="requestReset__title">Forgotten your password?</p>
      <p class="disclaimer">
        Enter your email address below to request a password reset.
      </p>
      <p class="submitMessage" v-if="submitted">Please check your email for a reset link.</p>
      <p class="error" v-if="error">{{ error }}</p>
      <div class="field">
        <span class="icon input-icon">
          <i class="far fa-envelope"></i>
        </span>
        <input
          type="email"
          class="input has-icon"
          autocomplete="email"
          placeholder="Your email address"
          v-model="requestResetData.email"
          @input="$emit('clear-error')"
        />
      </div>
      <div class="button-container">
        <button class="requestReset__button" :class="{ submitted: submitted === true }" @click="handleSubmit">{{ buttonText }}</button>
        <p class="disclaimer forgot-link">
          <router-link to="/login">
            Return to Login
          </router-link>
        </p>
      </div>
      <hr />
    </form>
  </div>
</template>

<script>
export default {
  name: 'RequestPasswordReset',
  props: ['error'],
  data () {
    return {
      submitted: false,
      requestResetData: {
        email: ''
      }
    }
  },
  computed: {
    buttonText () {
      return this.submitted
        ? 'Request Submitted'
        : 'Submit'
    }
  },
  methods: {
    handleSubmit () {
      this.submitted = true
      setTimeout(() => {
        this.submitted = false
        this.$router.push('/login')
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import '@/assets/styles/components.scss';
@import "@/assets/styles/requestReset.scss";

.disclaimer {
  text-align: center;
  font-size: 0.8rem;
}

.error {
  color: red;
  text-align: center;
}

.submitMessage {
  color: black;
  text-align: center;
}

.forgot-link {
  text-align: right;
}

.button-container {
  width: 100%;
}

.submitted {
  background: linear-gradient(60deg, #00b3bb 0%, #34319e 100%) !important;
}
</style>
