import Fuse from 'fuse.js'

export default {
  data: () => ({
    fuse: null,
    searchResults: [],
    searchTerm: '',
  }),
  computed: {
    filteredUsers() {
      return (this.searchTerm.length < 3)
        ? [...this.users]
        : [...this.users].filter(a => this.searchResults.includes(a.id))
    }
  },
  methods: {
    search() {
      if (!this.fuse) {
        this.fuse = new Fuse(this.users, {
          includeScore: true,
          includeMatches: true,
          threshold: 0.25,
          keys: ['name', 'email', 'service', 'role', 'organisationName']
        })
      }

      this.searchResults = this.fuse
        .search(this.searchTerm)
        .sort((a, b) => a.score > b.score ? -1 : 1)
        .map(r => r.item.id)
    },
  },
}
