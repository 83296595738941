<template>
  <div class="passwordReset__form">
    <form @submit.prevent="$emit('password-reset', resetData)" class="form">
      <img src="@/assets/images/logo.png" width="180" alt="Invision Logo" />

      <!-- No errors, validating token. -->
      <div v-if="isTokenValid === null && !error" class="validate-token">
        <h5>Validating your Reset Token.</h5>
        <div class="loading"></div>
      </div>

      <div v-if="isTokenValid === false && !error" class="validate-token">
        <h5>Invalid Token</h5>
        <p>Your token may have already been used or expired.</p>
        <p>Check that the link your copied correctly, or try requesting a password reset on the
          <router-link to="/forgotten">Forgot Password</router-link> page.
        </p>
      </div>

      <!-- Token is valid. -->
      <h5 v-if="isTokenValid === true" class="h5asswordReset__title">Reset your password</h5>

      <p v-if="isTokenValid === true" class="conditions" :style="hideInputs">
        In order to protect your account, your password must:
        <ul>
          <li>
            <span :style="{ color: this.validation.length ? '#8bc34a': '#c34a66' }">
              {{this.validation.length ? "&#10004;" : "&#10006;"}}
            </span> Be a minimum of 12 characters.
          </li>
          <li>
            <span :style="{ color: this.validation.capital ? '#8bc34a': '#c34a66' }">
              {{this.validation.capital ? "&#10004;" : "&#10006;"}}
            </span> Contain at least one uppercase letter.
          </li>
          <li>
            <span :style="{ color: this.validation.lower ? '#8bc34a': '#c34a66' }">
              {{this.validation.lower ? "&#10004;" : "&#10006;"}}
            </span> Contain a lowercase letter.
          </li>
          <li>
            <span :style="{ color: this.validation.number ? '#8bc34a': '#c34a66' }">
              {{this.validation.number ? "&#10004;" : "&#10006;"}}
            </span> Contain a number.
          </li>
          <li>
            <span :style="{ color: this.validation.special ? '#8bc34a': '#c34a66' }">
              {{this.validation.special ? "&#10004;" : "&#10006;"}}
            </span> Contain a special symbol (spaces not included).
          </li>
        </ul>
      </p>

      <p class="submitMessage" v-if="isTokenValid === true && submitMessage">{{ submitMessage }}</p>
      <p class="error" v-if="isTokenValid === true && error">{{ error }}</p>

      <div v-if="isTokenValid === true" class="field" :style="hideInputs">
        <span class="icon input-icon">
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 22H3C1.3 22 0 20.7 0 19V12C0 10.3 1.3 9 3 9H17C18.7 9 20 10.3 20 12V19C20 20.7 18.7 22 17 22ZM3 11C2.4 11 2 11.4 2 12V19C2 19.6 2.4 20 3 20H17C17.6 20 18 19.6 18 19V12C18 11.4 17.6 11 17 11H3Z"
            />
            <path
              d="M5 11C4.4 11 4 10.6 4 10V6C4 4.4 4.6 2.9 5.8 1.8C6.9 0.6 8.4 0 10 0C12.8 0 15.3 2 15.9 4.8C16 5.3 15.6 5.9 15.1 6C14.6 6.1 14 5.8 13.9 5.2C13.5 3.3 11.9 2 10 2C8.9 2 7.9 2.4 7.2 3.2C6.4 3.9 6 4.9 6 6V10C6 10.6 5.6 11 5 11Z"
            />
          </svg>
        </span>
        <span class="icon input-icon icon-end">
          <i @click="togglePassword" :class="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"></i>
        </span>
        <input
          :type="this.showPassword ? 'text' : 'password'"
          :style="{ borderWidth: '2px', borderColor: resetData.passwordOne ? validation.valid && '#8bc34a' || 'darkorange' : 'initial' }"
          class="input has-icon"
          autocomplete="password"
          placeholder="New password"
          v-model="resetData.passwordOne"
          @input="$emit('clear-error')"
        />
      </div>
      <div v-if="isTokenValid === true" :style="hideInputs" class="field">
        <label><input type="checkbox" v-model="showPassword"/> Show Password</label>
      </div>
      
      <div class="button-container">
        <button
          v-if="isTokenValid === true"
          class="passwordReset__button"
          :disabled='!validation.valid'
          :style="hideInputs"
        >
          Reset Password
        </button>
        <p class="disclaimer forgot-link">
          <router-link to="/login">
            Return to Login
          </router-link>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PasswordResetForm',
  props: ['error', 'submitMessage'],
  data () {
    return {
      resetData: {
        passwordOne: '',
      },
      showPassword: false,
      submitted: false
    }
  },
  computed: {
    ...mapGetters({ isTokenValid: 'user/validResetToken' }),
    hideInputs () {
      if (this.submitMessage === 'Password reset successful. Please return to login.') {
        return {
          display: 'none'
        }
      }
      return ''
    },
    validationColor (bool) {
      return { color: bool ? 'green': 'red' };
    },
    validation () {
      const password = this.resetData.passwordOne || '';

      /**
       * Mirrored from server side.
       */
      const length = password.length >= 12;
      const capital = /[A-Z]{1}/g.test(password);
      const lower = /[a-z]{1}/g.test(password);
      const number = /[0-9]{1}/g.test(password);
      const special = /[!@#$%^&*()+_\-=}{[\]|:;"\/?.><,`~]{1}/g.test(password) && !/[\s]/g.test(password);

      return {
        length,
        capital,
        lower,
        number,
        special,
        valid: length && capital && lower && number && special,
      };
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import '@/assets/styles/components.scss';
@import "@/assets/styles/passwordReset.scss";

.disclaimer {
  text-align: center;
  font-size: 0.8rem;
}

.conditions ul {
  list-style-type: none;
}

.error {
  color: red;
  text-align: center;
}

.submitMessage {
  color: black;
  text-align: center;
}

.forgot-link {
  text-align: right;
}

.button-container {
  width: 100%;
}

.warning {
  font-size: 16px;
}

.underline {
  text-decoration: underline;
}

div.validate-token {
  text-align: center;
}

div.validate-token p {
  margin: 15px 0;
}

div.validate-token div.loading {
  margin: 20px 0;
}

button:disabled {
  opacity: .6;
  cursor: not-allowed;
}
</style>
