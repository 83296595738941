<template>
  <div class="modal" v-show="value" :class="{ 'is-active': value }">
    <div class="modal-background" @click.stop="close" />
    <div class="modal-content">
      <div class="modal-title">{{ title }}</div>
      <div class="modal-body">
        <slot></slot>
      </div>
      <div class="modal-footer">
        <button class="button is-gradient" @click="save">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      required: true
    },
    value: {
      required: true
    }
  },
   methods: {
    close() {
      this.$emit("input", !this.value)
    },
    save() {
      this.$emit('save')
    }
   }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/modal.scss";
</style>
