<template>
  <div
    class="grade"
    :class="{
      selected: activated && selected,
      'not-selected': activated && !selected,
    }"
    :style="style"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'GradeButton',
  props: ['grade', 'count', 'activated', 'selected'],
  data () {
    return {
      grades: {
        gold: {
          text: 'Gold',
          color: 'linear-gradient(135deg, #fceabb 0%,#fccd4d 50%,#FFCB4B 51%,#fbdf93 100%)'
          // color: '#f8b500'
        },
        silver: {
          text: 'Silver',
          color: 'linear-gradient(135deg, #e2e2e2 0%,#D4D6E4 50%,#D4D6E4 51%,#fefefe 100%)'
          // color: '#d1d1d1'
        },
        bronze: {
          text: 'Bronze',
          color: 'linear-gradient(135deg, #f3e2c7 0%,#FF9328 50%,#FF9328 51%,#e9d4b3 100%)'
          // color: '#b68d4c'
        },
        true: {
          text: 'Clearly Included',
          color: '#ABA2CE'
        },
        false: {
          text: 'Not Included',
          color: '#D4D6E4'
        }
      }
    }
  },
  computed: {
    style () {
      return {
        background: this.grades[this.grade].color
      }
    },
    text () {
      return this.grades[this.grade].text
    }
  },
  methods: {
    select () {
      this.$emit('grade-selected', this.grade)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.grade {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 99px;
  border: unset;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  // transition: box-shadow 150ms ease-in-out;
  font-size: 0.9rem;
  // line-height: 1.25rem;
  // color: white;
  font-weight: 600;
  font-family: AllRoundGothicBook;
  display: flex;
  align-items: center;
  justify-content: center;
  // transition: all 150ms ease-in-out;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.grade.selected {
  opacity: 1;
}
.grade.not-selected {
  opacity: 0.3;
}
</style>