import { mapGetters } from 'vuex'

export default {
  data: () => ({
    defaultRouteForRole: {
      super: '/super/orgs',
      admin: '/admin/users',
      audit: '/review/select',
      report: '/reports/dashboard',
    }
  }),
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    navigateToDefaultRoute() {
      let route = this.defaultRouteForRole[this.user.role]
      if (route) {
        return this.$router.push(route)
      }
    },
  },
}
