<template>
  <div id="app">
    <router-view /> 
  </div>
</template>
<script>

export default {
  metaInfo: {
    script: [
    process.env.VUE_APP_HB_ENV === 'production' ?
      {
        id:"ze-snippet",
        src: 'https://static.zdassets.com/ekr/snippet.js?key=698cde4f-c3a4-40c2-840d-c0eaaa9d90b4',
        async: true,
        defer: true
      }
      : {}
    ]
  },
}

</script>
