import { format } from "date-fns";
import moment from "moment";

export default {
  filters: {
    formatDate(val) {
      // console.log(val);
      if (val) {
        try {
          if (moment(val, "DD/MM/YYYY", true).isValid()) {
            return moment(val, "DD/MM/YYYY").format("DD/MM/YYYY");
          }

          return moment(val).format("DD/MM/YYYY");
        } catch (e) {
          // Error gracefully.
          return "Invalid date";
        }
      }
      return "-";
    },
    formatDateTime(val) {
      if (val) {
        return format(new Date(val), "PPpp");
      }
      return "-";
    },
  },
};
