const Role = {
  Admin: 'admin',
  Audit: 'audit',
  Report: 'report',
  Super: 'super',
}

export {
  Role,
}
