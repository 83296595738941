<template>
  <div class="login__form">
    <form class="form" v-on:submit.prevent>
      
      <div class="login__header">
        <div class="login__logo">
          <img  src="@/assets/images/logo.png" width="180" alt="Invision Logo" />
        </div>
       <p class="login__title">Annual Review QA Tool Verification</p>
      </div>

      <div class="login__feedback">
        <p class="login__error" v-if="isError">
          <span>{{ message }}</span>
        </p>
        <p class="login__message" v-if="!isError">
          <span>{{ message }}</span>
        </p>
      </div>
      
      <div class="login__otp" v-bind:class="{error: isError}">
          <OtpInput
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            input-type="number"
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            :isDisabled="hasFailedVerification"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />
      </div>

      <div class="login__resend" v-if="!hasFailedVerification">
        <p>Haven't received a verification code?</p>
        <a class="login__resend" href="#" @click="handleResend()" v-if="!isTimerEnabled">Resend</a>
        <div class="login__resend" v-if="isTimerEnabled">
          <p>Resend in {{ timerCount }} seconds.</p>
        </div>
      </div>

      <div class="login__resend" v-if="hasFailedVerification">
        <p>Return to <a class="login__resend" href="/login" >Login</a></p>
      </div>

      <button class="login__button" @click="handleSubmit()" :disabled="hasFailedVerification">Verify</button>

      <hr />
      <p class="disclaimer">
        If you are having trouble with verifying your login or are not receiving your verification code, please contact your administrator.
      </p>
    </form>
  </div>
</template>


<script>
import OtpInput from '@/components/forms/otpVerification/OtpInput.vue';

export default {
  name: 'OtpVerificationForm',
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    countDown: {
      type: Number,
      default: 60,
    },
    hasFailedVerification: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      otp: null,
      otpComplete: false,
      isTimerEnabled: true,
      timerCount: this.countDown,
    }
  },
  watch: {
    isTimerEnabled(value) {
      if (value) {
        setTimeout(() => {
            this.timerCount--
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.isTimerEnabled) {
          setTimeout(() => {
              this.timerCount--
          }, 1000);

          return
        }

        this.isTimerEnabled = false
        this.timerCount = this.countDown
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  components: {
    OtpInput,
  },
  methods: {
    handleOnComplete(value) {
      this.otp = value;
      this.otpComplete = true;
      this.$emit('otpVerificationComplete', this.otp);
    },
    handleOnChange(value) {
      this.otp = value;
      this.otpComplete = false;
      this.$emit('otpVerificationChange', this.otp);
    },
    handleSubmit() {
      this.$emit('otpVerificationSubmit', { otp: this.otp, isOtpComplete: this.otpComplete });
    },
    handleResend() {
      this.$refs.otpInput.clearInput();
      this.$emit('otpResend');

      this.isTimerEnabled = true
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    optClasses() {
      return 'otp-input'
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables.scss";

.login {

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    margin-bottom: 1rem;
  }

  &__form {
    position: relative;
    background: white;
    box-shadow: 0px 10px 20px rgba(100, 100, 100, 0.5);
    border-radius: 16px;
    height: 570px;
    width: 460px;
    padding: 1.5rem 2rem;
  }

  &__title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__strap {
    font-size: 0.9rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
  }

  &__resend {
    font-size: 0.9rem;
    text-align: center;
  }

  &__button {
    background: $brand-gradient;
    height: 2.5rem;
    width: 100%;
    margin-bottom: 14px;
    border-radius: 18px;
    border: none;
    font-size: 1rem;
    color: white;
    font-weight: 600;
    cursor: pointer;

    &:disabled {
      background: $na-color;
      border: 1px solid grey;
      color: grey;
      cursor: not-allowed;
    }
  }

  &__feedback {
    min-height: 30px;
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.8rem;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  } 

  &__message {
    color: $brand-primary;
  }

  &__error {
    color: red;
  }

  &__otp {
    margin-bottom: 1.5rem;
  }

  .form {
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  hr {
    border: 0px;
    border-bottom: 1px solid $fill-grey;
    width: 100%;
  }

  .disclaimer {
    text-align: center;
    font-size: 0.8rem;
  }

  .error {
    color: red;
    text-align: center;
  }

  .forgot-link {
    text-align: right;
    margin-bottom: 15px;
  }
}

.otp-input {
    width: 50px;
    height: 50px;
    padding: 3px;
    margin: 0 4px;
    font-size: 20px;
    border-radius: 24px;
    border: 1px solid $black-30pc;
    text-align: center;
    font-weight: 600;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.error {
  .otp-input {
    border: 1px solid red;

    &:disabled {
      background: $na-color;
      border: 1px solid grey;
      color: grey;
      cursor: not-allowed;
    }
  }
}
</style>