<template>
  <div class="dashboard-section-header">
    <div class="title">
      <h3 class="section-title">{{ title }}</h3>
      <p class="section-subtitle">{{ subtitle }}</p>
    </div>
    <div class="filters">
      <slot name="filters"></slot>
    </div>
    <div class="searchbar">
      <slot name="searchbar"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: String,
    subtitle: String,
  },
}
</script>

<style lang="scss" scoped>
.dashboard-section-header {
  display: flex;
  & > div {
    flex: 1;
  }
  .searchbar {
    flex-basis: 300px;
    flex-grow: 0;
  }
  .filters {
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
    padding-right: 10px;
  }
  .title {
    .section-title {
      margin: 0;
      font-weight: 600;
      font-size: 1.25rem;
    }
    .section-subtitle {
      font-size: 1rem;
      font-weight: 400;
      margin-top: 0.4rem;
      &--bold {
        font-weight: 600;
      }
    }
  }
}
</style>
