<template>
  <div class="dashboard-section-footer">
    <div>
      <p
        :class="{ disabled: !canClickBackButton }"
        class="link link--back"
        v-show="showBackButton"
      >
        <a @click="backClicked">
          <i class="fas fas-button fa-chevron-circle-left"></i> {{ backButtonText }}
        </a>
      </p>
    </div>
    <div>
      <p
        :class="{ disabled: !canClickNextButton }"
        class="link" 
      >
        <a @click="nextClicked" :disabled="!canClickNextButton">{{ nextButtonText }} 
          <i class="fas fas-button fa-chevron-circle-right"></i>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionFooter',
  props: {
    backButtonText: {
      type: String,
      default: 'Back'
    },
    nextButtonText: {
      type: String,
      default: 'Next'
    },
    canClickBackButton: {
      type: Boolean,
      default: true
    },
    canClickNextButton: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    backClicked () {
      this.$emit('backClicked')
    },
    nextClicked () {
      this.$emit('nextClicked')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
.dashboard-section-footer {
  display: flex;
  justify-content: space-between;
}
</style>