import Fuse from 'fuse.js'

export default {
  data: () => ({
    fuse: null,
    searchResults: [],
    searchTerm: '',
  }),
  computed: {
    filteredOrganisations() {
      console.log({orgs: this.organisations})
      return (this.searchTerm.length < 3)
        ? this.organisations
        : this.organisations.filter(a => this.searchResults.includes(a.id))
    }
  },
  methods: {
    search() {
      if (!this.fuse) {
        this.fuse = new Fuse(this.organisations, {
          includeScore: true,
          includeMatches: true,
          threshold: 0.25,
          keys: ['name']
        })
      }

      this.searchResults = this.fuse
        .search(this.searchTerm)
        .sort((a, b) => a.score > b.score ? -1 : 1)
        .map(r => r.item.id)
    },
  }
}
