import { colorLookup, labelDescription } from "../style/gradingValues"
import { outcomeGrades, formatGrades, getGradingColor } from '../_helpers/grading'


export default {
  data: () => ({
    colorLookup: colorLookup,
    labelDescription: labelDescription
  }),
  methods: {
    determineOutcome (val) {
      const gradingObj = outcomeGrades
      let resultColor = 'Bronze'

      Object.keys(gradingObj).forEach((grade) => {
        if (gradingObj[grade].min <= val && val <= gradingObj[grade].max) {
            resultColor = grade
        }
      })

      return resultColor
    },
    determineSectionOutcome (val) {
      const gradingObj = formatGrades
      let resultColor = 'Bronze'

      Object.keys(gradingObj).forEach((grade) => {
        if (gradingObj[grade].min <= val && val <= gradingObj[grade].max) {
            resultColor = grade
        }
      })

      return resultColor
    }
  }
}
