<template>
  <li
    class="plan-list-item"
    @click="toggleSelect(plan.id)"
    :class="{'active-item': selected}"
  >
    <p class="item-header">
      {{ plan.internalId ? 'C/YP ID' : 'Invision ID'}} :
      {{ plan.internalId || plan.ehcpId }}
    </p>
    <div class="item-container">
      <div class="item-detail">
        <div class="detail-property">
          {{plan.keyStage}}
        </div>
        <div class="detail-label">
          Upload Date:
        </div>
      </div>
      <div class="item-detail">
        <div class="detail-property">
          {{plan.settingName}}
        </div>
        <div class="detail-property">
          {{ plan.createdAt | formatDate }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import DateFilter from '@/_mixins/date-filter.mixin'
export default {
  name: 'PlanListItem',
  mixins: [DateFilter],
  props: {
    selected: Boolean,
    plan: Object
  },
  methods: {
    toggleSelect (id) {
      this.$emit('click', id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.plan-list-item {
  list-style: none;
  padding: 0 0.5rem;
  padding-top: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    @extend .active-item;
  }

  .item-header {
    text-transform: uppercase;
    font-size: 0.8rem;
    border-bottom: 1px solid rgab(0, 0, 0, 0.25);
    font-weight: 600;
  }

  .item-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 0.5rem 0;
    .item-detail {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      .detail-label {
        text-transform: uppercase;
        font-size: 0.75rem;
      }
      .detail-property {
        font-size: 0.75rem;
        margin-right: 1rem;
      }
    }
  }
}

.active-item {
  cursor: pointer;
  color: white;
  background: $brand-gradient;
}
</style>