<template>
  <div class="navbar">
    <ul class="navitems">
      <li
        v-if="['admin', 'audit'].includes(role)"
        @click="pushRouteTo('/review/select')"
        :class="{ active: $route.path.indexOf('review') > 0 }"
      >
        <i class="far fa-calendar-alt"></i>
        <span>&nbsp;Audit an Annual Review</span>
      </li>
      <li
        @click="pushRouteTo('/reports/dashboard')"
        :class="{ active: $route.path.indexOf('reports') > 0 }"
      >
        <i class="far fa-clipboard"></i>
        <span>&nbsp;Reports</span>
      </li>
      <li
        v-if="['admin'].includes(role)"
        @click="pushRouteTo('/admin/users')"
        :class="{ active: $route.path.indexOf('admin') > 0 }"
      >
        <i class="fas fa-database"></i>
        <span>&nbsp;Coordinator</span>
      </li>
      <li
        @click="pushRouteTo('/support')"
        :class="{ active: active('support') }"
      >
        <i class="far fa-life-ring"></i>
        <span>&nbsp;Support</span>
      </li>
    </ul>
  </div>
</template>

<script>

const allRoutes = [
  { path: '/review/select', name: 'select' },
  { path: '/reports/dashboard', name: 'reports' },
  { path: '/admin/users', name: 'coordinator' },
  { path: '/support', name: 'support' }
]

export default {
  name: 'TopNav',
  props: {
    role: String
  },
  methods: {
    active(page) {
      return (this.$route.path.indexOf(page) > 0)
    },
    pushRouteTo(route) {
      try {
        const routeRequest = allRoutes.find(r => r.path === route)
        const isRouteActive = this.active(routeRequest.name)

        if (!isRouteActive) {
          this.$router.push(route);
        }
      } catch (error) {
        if (!(error instanceof NavigationDuplicated)) {
          throw error;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

div.navbar {
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  margin-bottom: $margin1x;
  margin-top: $margin1x;
  padding: 0 1rem;

  ul.navitems {
    display: inline-flex;
    height: 100%;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      border-radius: 99px;
      cursor: pointer;
      font-weight: 600;
      list-style-type: none;
      margin-top: 0.5rem;
      padding: 0.5rem 1rem;
      text-align: center;

      &:hover,
      &:focus,
      &.active,
      &.selected {
        @extend .active-option;
      }
    }

    a {
      text-decoration: unset;

      li {
        color: lightgrey;
        margin: $margin-half;
        padding: $padding-half;
      }

      &.is-active > li {
        @extend .active-option;
      }
    }
  }
}

.active-option {
  // background: linear-gradient(90deg, #ed6762 0%, #ea5393 100%);
  background: $brand-gradient;
  color: white !important;
}
</style>