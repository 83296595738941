const gradingHexColors = {
    Gold: '#FFCB4B',
    Silver: '#D4D6E4',
    Bronze: '#FF9328'
}

// outcomeGrade values
const outcomeGrades = {
    Gold: { min: 15, max: 100 },
    Silver: { min: 9.6, max: 14.99 },
    Bronze: { min: 0, max: 9.59 }
}

// formatGrade values
const formatGrades = {
    Gold: { min: 2.36, max: 100 },
    Silver: { min: 1.46, max: 2.359 },
    Bronze: { min: 0, max: 1.459 }
}

// formatGrade values
const formatTheme6Grades = {
    Gold: { min: 2.51, max: 100 },
    Silver: { min: 1.51, max: 2.509 },
    Bronze: { min: 0, max: 1.509 }
}

// const grades = {
//     Gold: { min: 2.26, max: 100 },
//     Silver: { min: 1.31, max: 2.25 },
//     Bronze: { min: 0, max: 1.3 }
// }

// Return grading color from gradingObj
const getGradingColor = (val = 0, gradingObj = {}, color = 'Bronze') => {
    let res = color;
    const gradeFunc = (grade) => {
        res = gradingObj[grade].min <= val && val <= gradingObj[grade].max
            ? grade
            : color
    }

    Object.keys(gradingObj).forEach(gradeFunc)
  
    return res
  }

export {
    gradingHexColors,
    outcomeGrades,
    formatGrades,
    formatTheme6Grades,
    getGradingColor
}