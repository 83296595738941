<template>
  <ul class="review-plan-list">
    <PlanListItem
      v-for="plan in plans"
      :key="plan.id"
      :plan="plan"
      :selected="selected.some(f => f.planId === plan.id)"
      @click="selectPlan(plan.id)"
    />
  </ul>
</template>

<script>
import PlanListItem from '@/components/PlanListItem.vue'

export default {
  name: 'PlanList',
  components: { PlanListItem },
  props: {
    plans: Array,
    selected: Array,
  },
  data () {
    return {
      selectedPlan: null,
    }
  },
  methods: {
    selectPlan (id) {
      this.$emit('plan-selected', id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.review-plan-list {
  padding: 0;
  margin: 0;
}
</style>