const validatePrimaryNeed = (primaryNeed) => {
  // ENUM('ASD', 'SEMH', 'SLCN', 'Physical', 'Sensory', 'VI', 'HI', 'MSI', 'MLD', 'SLD', 'SpLD', 'PMLD', 'Other')
  const upperCasePrimaryName = primaryNeed ? primaryNeed.toUpperCase().replace(/ /g, '') : null

  switch (upperCasePrimaryName) {
    case 'ASD':
      return 'ASD'
    case 'SEMH':
      return 'SEMH'
    case 'SLCN':
      return 'SLCN'
    case 'PHYSICAL':
      return 'Physical'
    case 'SENSORY':
      return 'Sensory'
    case 'VI':
      return 'VI'
    case 'HI':
      return 'HI'
    case 'MSI':
      return 'MSI'
    case 'MLD':
      return 'MLD'
    case 'SLD':
      return 'SLD'
    case 'SPLD':
      return 'SpLD'
    case 'PMLD':
      return 'PMLD'
    case 'OTHER':
      return 'Other'
    default:
      throw new Error(
        'PrimaryNeed value is not recognised. Please check your data. ' + primaryNeed
      )
  }
}

export {
  validatePrimaryNeed
}