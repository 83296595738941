<template>
  <div class="field">
    <label>{{ label }}</label>
    <span class="icon input-icon">
      <i :class="icon"></i>
    </span>
    <div class="select">
      <select @change="$emit('input', $event.target.value)">
        <option disabled selected value> -- select an option -- </option>
        <option
          v-for="option in options" 
          :key="option" :value="option"
          :selected="selectedOption(option)"
        >
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  },
  methods: {
    selectedOption(option) {
      return (this.value)
        ? option === this.value
        : false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  label {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
  span.icon {
    bottom: 0rem;
  }
  .select select {
    display: inline-flex;
    outline: 0;
    appearance: none;
    height: 2.5rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 99px;
    color: #363636;
    width: 100%;
    // min-width: 150px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    padding: 0.25rem 1rem;
    line-height: 1.5;
    padding-left: 3rem;
  }
}
</style>
