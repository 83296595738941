<template>
  <div class="field">
    <label>{{label}}</label>
    <span class="icon input-icon">
      <i :class="icon"></i>
    </span>
    <input
      :type="type"
      v-on="$listeners"
      :value="value"
      class="input has-icon"
      @input="$emit('update', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    icon: {
      type: String,
      default: ""
    }
  },
  model: {
    prop: "value",
    event: "update"
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  label {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
  span.icon {
    bottom: 0rem;
  }
}
</style>
